import logo from './logo.svg';
import './App.css';
import { useEffect, useState } from 'react';

function App() {
  let time = new Date().toLocaleTimeString();
  const [ctime,setCtime] = useState(time);

  const UpdateTime = () => {
    time = new Date().toLocaleTimeString();
    setCtime(time);
  };

  setInterval(UpdateTime,1000);

  
  const [input,setInput] = useState('')
  const [term,setTerm] = useState('Jakarta')
  const [data,setData] = useState({})
  const prayerTime = ['Fajr','Dhuhr', 'Asr','Maghrib','Isha']

  useEffect(()=>{
    fetch(`https://api.aladhan.com/v1/timingsByAddress?address=${term}`)
    .then(res => res.json())
    .then(res => setData(res.data));
  },[term])
  return (
    <div className="h-screen flex flex-col bg-[url('./assets/bg-masjid.jpg')] bg-cover text-white gap-5 justify-center items-center">
      <div className='flex flex-col w-full justify-between items-center gap-3 absolute top-5'>
        <div className='w-full flex justify-between px-[50px] text-[#e3e6e3]'>
          <div>
            <div className='text-2xl font-bold'>{data.date && data.date.hijri.month.en} </div>
            <div className='font-bold'>{data.date && data.date.hijri.weekday.en} {data.date && data.date.hijri.date}</div>
            <div className='font-bold'>{data.date && data.date.gregorian.weekday.en} {data.date && data.date.gregorian.date}</div>
          </div>
          <form onSubmit={(e)=>{
          e.preventDefault()
          setTerm(input)
          setInput('')
        }}>
          <input 
          value={input}
          onChange={(e)=> setInput(e.target.value)}
            type="text" 
            placeholder='Cari Kota'
            className='w-[w-250px] py-2 px-4 rounded-lg text-[14px] text-slate-400 outline-none'
            />
          <input type="submit" value=""/>
        </form>
        </div>
        <div className='text-5xl'>{term}</div>
      </div>
      
      <div className='text-9xl items-center'>{ctime}</div>

      {/* <div>
        <ul className='w-[200px] bg-[#0d0d0d] flex flex-col rounded-xl py-2'>
          { data &&
            prayerTime.map((p) => {
              return (
                <li className='odd:text-[#26a767] event:text-[#dbdbd9] flex justify-between p-2'>
                  <div>{p}</div>
                  <div className='font-bold'>{data.timings && data.timings[p]}</div>
                </li>
              );
            })
          }
        </ul>
      </div> */}

<div className='flex flex-row justify-around items-stretch text-black'>
          <div className='p-2 bg-yellow-200'>
            <h1 className='text-2xl items-center'>Shubuh</h1>
            <h1 className='text-6xl items-center'>{data.timings && data.timings['Fajr']}</h1>
          </div>
          <div className='p-2 bg-red-200'>
            <h1 className='text-2xl items-center '>Syuruq</h1>
            <h1 className='text-6xl items-center'>{data.timings && data.timings['Sunrise']}</h1>
          </div>
          <div className='p-2 bg-green-200'>
            <h1 className='text-2xl items-center'>Zhuhur</h1>
            <h1 className='text-6xl items-center'>{data.timings && data.timings['Dhuhr']}</h1>
          </div>
          <div className='p-2 bg-yellow-200'>
            <h1 className='text-2xl item-center'>Ashar</h1>
            <h1 className='text-6xl items-center'>{data.timings && data.timings['Asr']}</h1>
          </div>
          <div className='p-2 bg-red-200'>
            <h1 className='text-2xl item-center'>Magrib</h1>
            <h1 className='text-6xl items-center'>{data.timings && data.timings['Maghrib']}</h1>
          </div>
          <div className='p-2 bg-green-200'>
            <h1 className='text-2xl item-center'>Isya</h1>
            <h1 className='text-6xl items-center'>{data.timings && data.timings['Isha']}</h1>
          </div>
          <div className='p-2 bg-yellow-200'>
            <h1 className='text-2xl item-center'>Tengah Malam</h1>
            <h1 className='text-6xl items-center'>{data.timings && data.timings['Midnight']}</h1>
          </div>
          <div className='p-2 bg-red-200'>
            <h1 className='text-2xl item-center'>2/3 Malam</h1>
            <h1 className='text-6xl items-center'>{data.timings && data.timings['Lastthird']}</h1>
          </div>
      </div>
    </div>
    
    
  );
}

// Sumber : https://www.youtube.com/watch?v=z6KF3vrvWOM

export default App;
